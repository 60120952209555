import React from 'react'
import Layout from '../components/Layout'
import Search from '../components/Search'
import Seo from "gatsby-plugin-wpgraphql-seo";

const SearchResultsPage = ({ location }) => {
  return (
    <Layout>
      <Seo title="Search" />
      <Search location={location} showResourceFilters={false} showPostTypeFilters={true} />
    </Layout>
  )
}

export default SearchResultsPage